@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap);
@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"),
    url(/static/media/DancingScript-VariableFont_wght.d618761a.ttf) format("truetype");
  font-weight: normal;
}

body {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  background-image: url(/static/media/blob-scatter-haikei.2ed0f982.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* background-position: center; */
  /* background-color: var(--color-light) !important; */
}
.footer {
  background-image: url(/static/media/wave-haikei.ea9c8b3b.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}
.dancing__text {
  font-family: "DancingScript", "Work Sans", sans-serif;
  overflow: hidden;
  border-right: 0.15em solid var(--color-light);
  margin: 0 auto;
  white-space: nowrap;
  letter-spacing: 0.15em;
  -webkit-animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
          animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@-webkit-keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@-webkit-keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--color-light);
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--color-light);
  }
}

.space {
  margin-bottom: 100px;
}
.input-jast {
  border-radius: 0;
  height: 78px;
  /* background: var(--color-secondary); */
  /* box-shadow: 0px 2px 80px rgba(0, 0, 0, 0.13),
    0px 0.446726px 17.869px rgba(0, 0, 0, 0.0774939),
    0px 0.133002px 5.32008px rgba(0, 0, 0, 0.0525061); */
}

.btn {
  border: none;
  border-radius: 0;
}

.btn-primary {
  background: var(--color-primary);
}
.btn-secondary {
  background: var(--color-secondary);
}
.btn-primary:hover {
  background: var(--color-secondary);
}
.btn-secondary:hover {
  background: var(--color-primary);
}

.nav-link {
  font-weight: 500 !important;
  font-size: 18px;
  /* line-height: 21px; */
  /* color: var(--color-black) !important; */
}
.bg-secondary {
  background: var(--color-secondary) !important;
}
.bg-primary {
  background: var(--color-primary) !important;
}

.aw-header {
  /* background: linear-gradient(to bottom, var(--color-black) 0%, var(--color-yellow) 100%); */
  /* background-color: var(--color-black); */
  background-image: url(/static/media/header.46983028.jpg);
  text-transform: uppercase;
}

.aw-header-cover {
  background-color: rgba(0, 0, 0, 0.8);
}

.alert-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
  margin-bottom: 0px;
}

.cookies {
  position: fixed;
  background-color: var(--color-secondary);
  bottom: 30px;
  left: 30px;
  width: 300px;
  font-size: 12px;
}

.cookies-img {
  margin-top: -60px;
  margin-left: 100px;
}

.reviewsPopup {
  position: fixed;
  background-color: var(--color-secondary);
  bottom: 30px;
  right: 30px;
  width: 300px;
  font-size: 12px;
}
.reviewsPopup__img {
  margin-top: -100px;
}

.text-primary {
  color: var(--color-primary) !important;
}
.text-secondary {
  color: var(--color-secondary) !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .dark-nav {
    background-color: var(--color-black);
    margin-top: -30px;
    padding-top: 30px;
  }
  .dark-nav-text {
    color: var(--color-white);
  }
  .d-flex {
    display: flex !important;
  }
}

.navbar .megamenu {
  padding: 1rem;
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
  }
  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
}
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}
/* ============ mobile view .end// ============ */

/* ============ only desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }
  .navbar .nav-item .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    margin-top: 0;
  }

  .navbar .dropdown-menu.fade-down {
    top: 80%;
    -webkit-transform: rotateX(-75deg);
            transform: rotateX(-75deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  .navbar .dropdown-menu.fade-up {
    top: 180%;
  }
  .navbar .nav-item:hover .dropdown-menu {
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
/* ============ desktop view .end// ============ */

.dropdown-large {
  padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .dropdown-large {
    min-width: 500px;
  }
}
/* ============ desktop view .end// ============ */

.shadow__hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.card .reveal {
  visibility: hidden;
  transition: 0.3s;
  /* transform: translateY(100%); */
  opacity: 0;
}
.card:hover .reveal {
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 10;
  transition: opacity 0.3s ease;
}
.card_text_reveal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.863);
  color: #fff;
}
.solid__left {
  border-left: 10px solid var(--color-secondary);
  margin-left: 50px;
  padding-left: 30px;
}
.cool__icon {
  color: var(--color-secondary);
  font-size: 40px;
}
.btn:hover .cool__icon {
  color: var(--color-dark);
  transition: 0.3s;
  transition-timing-function: ease-in-out;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-transform: translate(-5px, 0px);
          transform: translate(-5px, 0px);
}
.rounded-4 {
  border-radius: 15px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

